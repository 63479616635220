<template>
	<!-- 头部 -->
	<div class="sticky">
		<Header></Header>
	</div>
	<!-- 背景图部分 -->
	<div class="introduceWrap flex align-center">
		<img :src="info.bg" style="width: 100%;height: 100%;position: absolute;top: 0;z-index: -1;">
		<div class="introduceChild flex">
			<img :src="info.image" style="width: 51px;height: 58px;margin-top: 10px;">
			<div class="bold colorfff" style="font-size: 48px;margin-left: 31px;">{{info.title}}</div>
		</div>
	</div>
	<!-- 文章部分 -->
	<div class="articleWrap">
		<div class="bold" style="font-size: 60px;margin-bottom: 60px;">{{post_title}}</div>
		<div v-html="post_content"></div>
	</div>
	<!-- 底部 -->
	<div style="margin-top: 131px;">
		<Footer></Footer>
	</div>
</template>
<script>
	import{
		article
	} from '../api/article.js'
	import Header from '../components/header.vue'
	import Footer from '../components/footer.vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		reactive
	} from '@vue/reactivity'
	import {
		onMounted,
		ref,
		toRefs,
    watch
	} from 'vue'
	export default {
		name: 'numberTechnology',
		components: {
			Header,
			Footer,
		},
		setup(props, ctx) {
			// 使用router跳转的方法
			const router = useRouter()
			// 获取router传参
			const route = useRoute()
			const data = reactive({
				info: {},
				post_title:'',//文章标题
				post_content:'',//文章内容
			});
			watch(()=>router.currentRoute.value.fullPath,(oldVal,newWal)=>{
				if(router.currentRoute.value.path=='/numberTechnology'){
					data.info = JSON.parse(route.query.query)
				}
				document.documentElement.scrollTop = 0;
			});
			onMounted(() => {
				// 滚动条到顶部
				document.documentElement.scrollTop = 0;
				// console.log(JSON.parse(route.query.query))
				data.info = JSON.parse(route.query.query)
				getArticle()
			});
			const getArticle = () =>{
				article().then(res=>{
					console.log(res,'文章')
					data.post_title = res.post_title
					data.post_content = res.post_content
				})
			}
			return {
				...toRefs(data)
			}
		}
	}
</script>
<style scoped>
	.articleWrap {
		margin: 8.1vw 20.7vw 0 20.2vw;
	}
	
	.introduceWrap{
		width: 100%;
		height: 350px;
		position: relative;
	}
	
	.introduceChild{
		padding: 0 0 0 20.3vw;
		box-sizing: border-box;
	}
</style>
