import request from "@/utils/request";

// 协议环节
export function article() {
  return request({
    url: `/article/get_article_detail/id/34`,
    method: "get",
  });
}

// 图片模糊匹配
export function imageCheck(info) {
  let JSESSIONID = Cookies.get("token");
  info = Object.assign(info, { JSESSIONID: JSESSIONID });
  return request({
    url: `register/image/check`,
    method: "post",
    data: info,
  });
}
