import axios from 'axios'
import {
	ElMessage
} from 'element-plus'
const service = axios.create({
	// baseURL: '/portal', // api的base_url
	baseURL: 'http://thinkcmf5.minft.cn/portal', // api的base_url
	timeout: 100000 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
	console.log('发送请求')
	// if (Cookies.get('token')) {
	//     config.headers['JSESSIONID'] = Cookies.get('token')
	// }
	// config.withCredentials = true
	return config
}, error => {
	Promise.reject(error)
})

service.interceptors.response.use(response => {
	console.log(response)
		if (response.status == '200') {
			return response.data
		} else if (response.data.errcode) {
			return response.data
		} else {
			ElMessage({
				message: response.data.errmsg,
				type: 'error',
				duration: 2 * 1000
			})
		}
	},
	error => {
		console.log('err' + error) // for debug
		ElMessage({
			message: error.message,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	})

export default service
